
import ImportForm from '@/components/Management/Store/ImportForm.vue';
export default {
  components: { ImportForm },
  methods: {
    async onImport(file: File) {
      try {
        const data = await this.$repo.store.importStores(file);
        await this.showUploadResult(data);
      } catch (err) {
        await this.showUploadResult(err.response.data);
      }
    },
    async showUploadResult(data) {
      const randomFactor = Math.random()
        .toString(36)
        .substr(2);
      const idSrc = `000000${randomFactor}`;
      const id = idSrc.substr(idSrc.length - 6);
      await this.$repo.store.saveImportResult(id, data);
      this.$router.push({
        name: 'management:store:import:result',
        params: {
          id
        }
      });
    }
  }
};
